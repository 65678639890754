/* hardware accelatator class */
.trans3d {
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform-style: preserve-3d;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform-style: preserve-3d;
  -ms-transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);

  /*-webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility:hidden;
  backface-visibility:hidden;*/
}


#contentContainer {
  position: fixed;
  margin-left: 0;
  margin-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

#carouselContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.carouselItem {
  z-index: 99999;
  width: 320px;
  height: 130px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -160px;
  margin-top: -90px;
  visibility: hidden;
}

.carouselItemInner {
  width: 350px;
  height: 500px;
  position: absolute;
  font-size: 72px;
  left: 50%;
  top: -100%;
  margin-left: -160px;
  margin-top: -90px;
  text-align: center;
  padding-top: 50px;
}

.carouselItemInner * {

}

.carouselMouseBlocker {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate3d(1000, 1000, -500px);
}