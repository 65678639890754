#trades {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
}

#trades .create {
    grid-column: span 2;
}
#trades b {
    color: #AEAEAE;
    line-height: 35px;
}

#trades #pending button {
    border: none;
    float: right;
    height: 100%;
    outline: none;
    padding: 4px 10px;
    width: 80px;
    background-color: var(--primary);
    color: white;
    border-radius: 5px;
}

#trades #pending button:hover {
    background-color: var(--primary);
}

#trades #sent b {
    color: #AEAEAE;
    line-height: 35px;
}

#trades .trades-list * {
    box-shadow: none;
}

#trades .trades-list a {
    text-decoration: none;
    color: #A4A4A4;
}

#trades .trades-list .trade {
    position: relative;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;
}
#trades .trades-list .trade:hover {
    background-color: rgba(0,0,0,.1);
}

#trades .trades-list .trade .avatar {
    float: left;
    max-height: 35px;
    margin-right: 10px;
    margin-top: 5px;
}

#trades .trades-list .trade .name {
    color: #A4A4A4;
    font-weight: 500;
    line-height: 0.8rem;
}
#trades .trades-list .trade:hover .name {
    color: #323232;
}

#trades .trades-list .trade .action {
    color: #545454;
    font-weight: 300;
    font-style: italic;
}
#trades .trades-list .trade:hover .action {
    color: #151515;
}

#trades .trades-list .trade::after {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    content: '';
    background-image: url(../../assets/icons/arrow_right.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 24px;
    width: 10px;
}

@media only screen and (min-width: 1000px) {
    #trades {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
}
/*# sourceMappingURL=trades.css.map */