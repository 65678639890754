#createoffer {
    display: grid;
    grid-gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
}

#createoffer .trader {
    background-color: white;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 10px;
    overflow: hidden;
}


#createoffer .trader .avatar {
    float: left;
    height: 35px;
    margin-right: 10px;
}

#createoffer .trader .info {
    float: left;
}

#createoffer .trader .name {
    color: #A4A4A4;
    font-weight: 500;
}

#createoffer .trader .action {
    color: #7d7d7d;
    font-weight: 300;
    font-style: italic;
}

#createoffer .trader button {
    margin: 4px 0;
    float: right;
}

#createoffer .search {
    padding: 5px 10px;
    border-radius: 5px;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #D0D0D0;
    color: #A4A4A4;
    outline: none;
}

#createoffer .items {
    background-color: white;
    padding-top: 10px;
}

.ItemView {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
    width: 125px;
    min-width: 125px;
    height: 175px;
}

/* @media only screen and (min-width: 1600px) {
    .ItemView {
        width: 175px;
        min-width: 175px;
        height: 250px;
    }
} */

.ItemView img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.25));
    cursor: pointer;
}

.ItemView .trash {
    top: unset;
    left: unset;
    bottom: 5px;
    right: 5px;
    transform: none;
    opacity: 0.6;
}
.ItemView .trash:hover {
    opacity: 1;
}

.ItemView.selected img {
    opacity: .5;
}

.ItemView.selected:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: '';
    height: 50px;
    width: 50%;
    background-image: url(../../assets/icons/check.svg);
}

#createoffer .items .addcard {
    position: relative;
    height: 175px;
    width: 125px;
    min-width: 125px;
    background-color: #F9F9F9;
    cursor: pointer;
    margin-right: 10px;
    float: left;
}

/* @media only screen and (min-width: 1600px) {
    #createoffer .items .addcard {
        width: 175px;
        min-width: 175px;
        height: 250px;
    }
} */


#createoffer .items .addcard img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#createoffer .items .addcard:hover {
    background-color: #ebe9e9;
}
#createoffer .trade-actions  {
    text-align: left;
}

#createoffer .trade-actions button {
    background-color: transparent;
    width: 100%;
    font-size: 1.2em;
    padding: 5px 0;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: var(--primary);
    border: 2px solid var(--primary);
}

#createoffer .trade-actions button:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

#createoffer .trade-actions .memo-input {
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    width: calc(100% - 30px);
    outline: none;
}

#createoffer .popup {
    width: 750px;
    height: 595px;
    max-height: 100%;
}

#createoffer .popup .items {
    height: 395px;
    max-height: calc(100% - 130px);
    overflow: auto;
    display: -ms-grid;
    display: grid;
    grid-gap: 10px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
}

#createoffer .popup .items .item {
    width: 100%;
}

@media only screen and (max-width: 300px) {
    #createoffer .popup .items {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 450px) {
    #createoffer .popup .items {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 600px) {
    #createoffer .popup .items {
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 750px) {
    #createoffer .popup .items {
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1000px) {
    #createoffer {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
    #createoffer .trade-actions {
        grid-column: span 2;
    }
}
/*# sourceMappingURL=createoffer.css.map */