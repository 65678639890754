#community {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    
}

#community .wrapper {
    width: 100%;
}

#community .title {
    color: #C4C4C4;
    font-style: italic;
    font-size: 20px;
    margin-bottom: 5px;
}

#community .wrapper .box {
    max-width: 100%;
    overflow-y: auto;
}

.followingItem {
    width: 100px;
    min-width: 100px;
    margin: 10px;
    text-align: center;
    float: left;
    color: #A4A4A4;
    cursor: pointer;
}

.followingItem:hover {
    text-decoration: underline;
}

.followingItem img {
    transition: transform 0.2s;
    height: 50px;
}
.followingItem:hover img  {
    transform: scale(1.05);
}

#community .wrapper .box .cards-wrapper {
    display: flex;
    width: 100%;
}

#community .wrapper .box .dropped-card {
    text-align: center;
    cursor: pointer;
    width: 250px;
    height: 350px;
}
#community .wrapper .box .dropped-card img {
    max-height: 100%;
    transition: transform 0.2s;
}
#community .wrapper .box .dropped-card:hover img {
    transform: scale(1.05);
}