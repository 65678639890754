#faq {
    text-align: left;
}
#faq h2.title {
    text-align: center;
    margin-bottom: 25px;
}

#faq .question {
    font-weight: 500;
    font-size: 24px;
    padding-top: 130px;
    margin-top: -130px;
}
#faq .answer {
    padding: 10px 0;
    color: #676767;
    font-size: 20px;
    font-weight: 300;
}
#faq .answer ul {
    width: 100%;
}
#faq .answer b {
    color: #898989;
    font-weight: 500;
}
#faq .answer a {
    text-decoration: none;
    color: var(--primary);
}