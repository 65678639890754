.inspector-wrapper {

}

.inspector-wrapper .inspector {

}

.black-dimmer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation: 3s ease-in 0s 1 transitionDimmer forwards;
  -webkit-animation: 3s ease-in 0s 1 transitionDimmer forwards;
  z-index: 99999;
}

@keyframes transitionDimmer {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}

.result-card-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20%;
  z-index: 99999;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  animation: resultCardAnimation;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes resultCardAnimation {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}


.craft-button-container {
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inspect-item {
  height: 60%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#inspect-community-markets {
  text-align: center;
  color: white;
}

#inspect-community-markets .market-button {
  margin: 10px;
  display: inline-block;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.craft-safari-fix {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 9999999;
  color: white;
}

.craft-safari-fix p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  user-select: none;
}
