:root {
  --card-mask-portrait: url(../../assets/images/card_mask_1.png);
  --card-mask-landscape: url(../../assets/images/card_mask_2.png);
  --card-mask-portrait-2: url(../../assets/images/card_mask_portrait.png);
  --card-mask-landscape-2: url(../../assets/images/card_mask_landscape.png);
}

.tilt3-front {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(1000px);
  -moz-transform: translate(-50%, -50%) perspective(1000px);
  -webkit-transform: translate(-50%, -50%) perspective(1000px);
}

.tilt3-back {
  position: absolute;
  visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(1000px);
  -moz-transform: translate(-50%, -50%) perspective(1000px);
  -webkit-transform: translate(-50%, -50%) perspective(1000px);
}

.tilt3-back-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg) perspective(1000px);
  -moz-transform: translate(-50%, -50%) rotate(0deg) perspective(1000px);
  -webkit-transform: translate(-50%, -50%) rotate(0deg) perspective(1000px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.tilt3-rotator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes cardanimation {
  0% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
  49.99% {
    transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -moz-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -webkit-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
  }
  50% {
    transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    -moz-transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    -webkit-transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
  }
  100% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
}

@keyframes frontanimation {
  0% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  49.99% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  50% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  100% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
}

@keyframes backanimation {
  0% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  49.99% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  50% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  100% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
}

@keyframes cardanimation2 {
  0% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
  49.99% {
    transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    -moz-transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    -webkit-transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
  }
  50% {
    transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -moz-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -webkit-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
  }
  100% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
}

@keyframes frontanimation2 {
  100% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  50% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  49.99% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  0% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
}

@keyframes backanimation2 {
  0% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  49.99% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  50% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  100% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
}

.tilt3-wrapper {
  overflow: visible;
  position: relative;
  transform: rotateX(0deg) rotateY(-5deg) perspective(1000px);
  -webkit-transform: rotateX(0deg) rotateY(-5deg) perspective(1000px);
  -moz-transform: rotateX(0deg) rotateY(-5deg) perspective(1000px);
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.3));
}

.tilt3-wrapper, .tilt3-back, .tilt3-front {
  animation-duration: 0.3s !important;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.2s !important;
  -webkit-animation-fill-mode: forwards !important;
}

.tilt3-wrapper.tilt3-show-backside {
  animation-name: cardanimation;
  -webkit-animation-name: cardanimation;
}

.tilt3-show-backside .tilt3-front {
  animation-name: frontanimation;
  -webkit-animation-name: frontanimation;
}

.tilt3-show-backside .tilt3-back {
  animation-name: backanimation;
  -webkit-animation-name: backanimation;
}

.tilt3-wrapper.tilt3-flip-back {
  animation-name: cardanimation2;
  -webkit-animation-name: cardanimation2;
}

.tilt3-flip-back .tilt3-front {
  animation-name: frontanimation2;
  -webkit-animation-name: frontanimation2;
}

.tilt3-flip-back .tilt3-back {
  animation-name: backanimation2;
  -webkit-animation-name: backanimation2;
}

.tilt3-img-sizer {
  visibility: hidden;
}

.tilt3-rotator {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -webkit-transform: perspective(1000px);
  flex-shrink: 0;
}

.tilt3-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: center;
}

.tilt3-card-shadow {
  transform: translateZ(-15px) scale(1.002) perspective(1000px);
  -webkit-transform: translateZ(-15px) scale(1.002) perspective(1000px);
  -moz-transform: translateZ(-15px) scale(1.002) perspective(1000px);
  background: #515151;
}


:root {
  --card-elevation: 20px;
}

.tilt3-card-inner-1 {
  transform: translateZ(var(--card-elevation)) perspective(1000px);
  -webkit-transform: translateZ(var(--card-elevation)) perspective(1000px);
  -moz-transform: translateZ(var(--card-elevation)) perspective(1000px);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.tilt3-card-inner-2 {
  transform: translateZ(15px) perspective(1000px);
  -webkit-transform: translateZ(15px) perspective(1000px);
  -moz-transform: translateZ(15px) perspective(1000px);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.tilt3-shadow {
  background-image: unset;
  background: #313131;
  transform: translateZ(0.1px);
  -moz-transform: translateZ(0.1px);
  -webkit-transform: translateZ(0.1px);
}

@keyframes fixsafari {

}

.wtf-safari {
  animation-name: fixsafari;
  -webkit-animation-name: fixsafari;
}

.card-ios-container {
  position: relative;
}

.card-ios-container img {

}

.card-ios-back {
  position: absolute;
  max-width: 100%;
  left: 0;
}

.card-ios-hidden {
  opacity: 0;
  pointer-events: none;
}

.plastic-id {
  position: absolute;
  top: 54.5%;
  transform: rotate(3deg);
  font-weight: bold;
  left: 0;
  width: 100%;
  text-align: center;
}

.mobile-plastic-id {
  position: absolute;
  top: 54.5%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: rotate(3deg);
}