#catalog {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

#catalog h1 {
  margin: 0;
  color: #C4C4C4;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 5px;
}

#catalog .card-list {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
#catalog .card-list .card-wrapper {
  margin: auto;
  display: flex;;

}
/*# sourceMappingURL=catalog.css.map */