
#invview.card-wrapper,
#invview.pack-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#invview.card-wrapper .CardView,
#invview.pack-wrapper .CardView {
  position: relative;
  border-radius: 5px;
  max-width: 100%;
  text-align: left;
  margin: 10px;
}


#invview.pack-wrapper .pack .preview {
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

#invview.pack-wrapper .pack .info {
  color: #888888;
  position: absolute;
  display: grid;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  width: calc(100%);
  opacity: 0;
  transition: opacity .2s;
}

#invview.pack-wrapper .pack:hover .info {
  opacity: 1;
}

#invview.pack-wrapper .pack .info button {
  width: calc(100% - 20px);
  margin: 10px;

}

.unpacking-dimmer {
  background-color: black;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 99999;
  animation: dim-animation linear 1s;
}

#dimming-video {
  width: 100%;
}

@keyframes dim-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}


@media only screen and (max-width: 480px) {
  #invview.card-wrapper, #invview.pack-wrapper {
    justify-content: space-evenly;
  }
}

@media only screen and (min-width: 600px) {
  #invview.card-wrapper, #invview.pack-wrapper {

  }
}

@media only screen and (min-width: 1000px) {
  #invview.card-wrapper, #invview.pack-wrapper {

  }
}

@media only screen and (min-width: 1500px) {
  #invview.card-wrapper, #invview.pack-wrapper {

  }
}

@media only screen and (min-width: 1800px) {
  #invview.card-wrapper, #invview.pack-wrapper {

  }
}

.unpacking-dimmer-hidden {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

