#shop {
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    width: calc(100% - 50px);
}

#shop h1 {
    font-weight: bold;
    font-size: 46px;
    margin: 0;
    color: #424242;
    text-align: center;
}

#shop .subtitle {
    text-align: center;
    font-weight: 500;
    margin: 0;
}

#shop .pack-wrapper {
    margin-bottom: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#shop .pack-wrapper h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    margin: 0;
    color: #2F2F2F;
}

#shop .pack-wrapper .pack {
    position: relative;
    width: 750px;
    max-width: 100%;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#shop .pack .sold-out-text {
    position: absolute;
    color: var(--primary);
    font-weight: 700;
    font-size: 75px;
    z-index: 1040;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-05deg);
    -webkit-transform: translate(-50%, -50%) rotate(-05deg);
    -ms-transform: translate(-50%, -50%) rotate(-05deg);
    text-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

#shop .pack .info {
    position: relative;
    overflow: hidden;
    padding-bottom: 2em;
}

#shop .pack .info .pack-counter {
    position: absolute;
    top: 40px;
    right: -120px;
    width: 350px;
    text-align: center;
    transform: rotate(45deg);
    background: var(--primary);
    color: white;
    font-size: 1rem;
    box-shadow: 0 0 12px rgba(0, 0, 0, .5);
}

#shop .pack .info.sold-out {
    filter: grayscale(1) blur(2px);
    -webkit-filter: grayscale(1) blur(2px);
}

#shop .pack .info .pack-image {
    position: relative;
    float: left;
    height: 350px;
    width: 250px;
    border-radius: 5px;
    margin-right: 10px;
    transform: translateY(-12px) scale(.7);
}

#shop .pack .info .details {
    margin-bottom: 50px;
}

#shop .pack .info .details .price {
    font-weight: 100;
    font-size: 1.5em;
    line-height: 1em;
    color: #565656;
    margin: 0px;
    margin-top: 10px;
    margin-right: 10px;
    float: right;
}

#shop .pack .info .details .description {
    line-height: 1.2rem;
    margin-top: 10px;
    float: left;
    transform: translateY(-5px);
    width: 65%;
}

#shop .pack .info .details .description b {
    font-size: 24px;
    line-height: 35px;
}

#shop .pack .info .details .cards {
    margin: 0;
    color: #565656;
    font-size: 1em;
    font-weight: 200;
}

#shop .pack .info .details .more-info {
    font-size: 24px;
}

#shop .pack .info .details .more-info .tag {
    line-height: 24px;
    margin-top: 10px;
}

#shop .pack .info .details .more-info .tag b {
    margin-right: 25px;
}

#shop .pack .form {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 250px;
    text-align: left;
}

#shop .pack .form button {
    width: calc(50% - 10px);
}

#shop .pack .form .catalog {
    float: right;
}


#shop #purchaseformbox {
    grid-column: span 2;
    width: 800px;
    max-width: calc(100% - 20px);
    margin: 0 auto;
}

#shop .purchaseFormRequired {
    grid-column: span 2;
    width: 800px;
    max-width: calc(100% - 20px);
    margin: 0 auto;
}

@media only screen and (min-width: 1600px) {
    #shop {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }

    #shop #purchaseformbox {
        grid-column: span 2;
    }

    #shop h1 {
        grid-column: span 2;
        display: block;
    }

    #shop .subtitle {
        grid-column: span 2;
        display: block;
    }

    #shop .pack-wrapper {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 800px) {
    #shop .pack-wrapper .pack {
        position: relative;
        width: 550px;
        max-width: 100%;
        background-color: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    #shop #purchaseformbox {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {

    #shop {
        width: calc(100%);
        padding: 10px 0;
        grid-template-columns: 1fr;
    }

    #shop h1 {
        font-size: 32px;
    }

    #shop .pack .info .pack-image-wrapper {
        width: 100%;
    }

    #shop .pack .info .pack-image-wrapper .pack-image {
        float: initial;
        display: block;
        margin: 0 auto;
    }

    #shop .pack-wrapper .pack {
        width: 100%;
    }

    #shop .pack .info img {
        width: 100%;
        height: initial;
        max-height: initial;
        float: initial;
        padding-bottom: 10px;
    }


    #shop .pack .info .details {
        margin-bottom: 15px;
    }

    #shop .pack .info .details .more-info {
        padding: 0 10px;
        text-align: right;
    }

    #shop .pack .info .details .more-info .tag:first-child {
        margin-top: 0;
    }

    #shop .pack .info .details .more-info b {
        float: left;
    }

    #shop .pack .info .details .description {
        font-size: 16px;
        padding: 0 10px;
        width: 100%;
    }

    #shop .pack .info .form {
        width: calc(100%);
        position: relative;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: left;
    }

    #shop .pack .info .form button {
        width: calc(100% - 20px);
        margin: 0px 10px;
        margin-bottom: 10px;
    }
}

/*# sourceMappingURL=shop.css.map */