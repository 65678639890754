#payment {
    grid-template-columns: 1fr 2fr ;
}

#payment .box {
    padding: 25px;
}

#payment h1 {
    margin: 0;
    text-align: center;
}
#payment h2 {
    margin-top: 0;
    font-weight: 300;
    font-style: italic;
    font-size: 22px;
    text-align: center;
}

#payment #summary .right {
    float: right;
}

#payment #summary .provider {
    text-transform: capitalize;
}

#payment #statusbox {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#payment #statusbox a { 
    color: var(--primary);
}
#payment #statusbox a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 1080px) {
    #payment {
        grid-template-columns: 1fr;
    }
    #payment #statusbox {
        grid-row-start: 1;
    }
}