.landing {
  /* position: fixed; */
  /* height: 100%; */
  width: 100%;
  background: black;
  color: white;
  /* overflow-y: auto; */
}

.landing *, .landing *:before, .landing *:after {
  box-sizing: inherit;
}

.landing hr {
  border-color: rgba(0, 0, 0, .2);
  border-top: 0;
}
.landing #landingpage {
  position: relative;
  width: calc(100% - 50px);
  max-width: 800px;
  padding: 25px;
  margin: 0 auto;
  text-align: center;
}
.landing #landingpage h1 {
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 50px;
  margin-top: 0;
}

.landing #landingpage .topps {
  -webkit-filter: drop-shadow(3px 2px 0px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(3px 2px 0px rgba(0, 0, 0, 0.25));
  height: 50px;
  padding-bottom: 15px;
}

.landing #landingpage .cards {
  width: 100%;
  padding-bottom: 50px;
}

.landing #landingpage .card {
  position: absolute;
  width: calc(25vw);
  max-width: 150px;
}

.landing #landingpage .card:nth-child(1) {
  -webkit-transform: translateX(-150%) translateY(55px) rotateZ(-45deg);
  transform: translateX(-150%) translateY(55px) rotateZ(-45deg);
}

.landing #landingpage .card:nth-child(2) {
  -webkit-transform: translateX(-100%) translateY(25px) rotateZ(-30deg);
  transform: translateX(-100%) translateY(25px) rotateZ(-30deg);
}

.landing #landingpage .card:nth-child(3) {
  -webkit-transform: translateX(-50%) translateY(5px) rotateZ(-15deg);
  transform: translateX(-50%) translateY(5px) rotateZ(-15deg);
}

.landing #landingpage .card:nth-child(4) {
  position: relative;
}

.landing #landingpage .card:nth-child(5) {
  -webkit-transform: translateX(-50%) translateY(5px) rotateZ(15deg);
  transform: translateX(-50%) translateY(5px) rotateZ(15deg);
}

.landing #landingpage .card:nth-child(6) {
  -webkit-transform: translateX(0%) translateY(25px) rotateZ(30deg);
  transform: translateX(0%) translateY(25px) rotateZ(30deg);
}

.landing #landingpage .card:nth-child(7) {
  -webkit-transform: translateX(50%) translateY(55px) rotateZ(45deg);
  transform: translateX(50%) translateY(55px) rotateZ(45deg);
}

.landing #landingpage .brand-logo {
  max-width: 100%;
  padding-bottom: 50px;
}

.landing #landingpage .actions {
  max-width: calc(100%);
  font-size: 15px;
}

.landing #landingpage .actions .continue {
  padding: 5px 0;
}

.landing #landingpage button {
  border: 2px solid white;
  background-color: transparent;
  width: calc(100% - 20px);
  font-size: 18px;
  padding: 10px;
  color: white;
  border-radius: 10px;
  margin: 5px 0;
  font-weight: 600;
  -webkit-transition: color 0.2s, background-color .2s;
  transition: color 0.2s, background-color .2s;
  outline: none;
  cursor: pointer;
}

.landing #landingpage button:hover {
  background-color: white;
  color: var(--primary);
}

.landing #landingpage button.full {
  background-color: white;
  color: var(--primary);
  border-color: transparent;
}

.landing #landingpage button.full:hover {
  -webkit-box-shadow: 0px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px rgba(0, 0, 0, 0.25);
}

.landing #landingpage a {
  color: white;
  text-decoration: none;
}

@media only screen and (min-width: 600px) {
  .landing #landingpage button {
    width: calc(50% - 20px);
    margin: 10px;
  }
  .landing #landingpage .actions {
    font-size: 20px;
  }
  .landing #landingpage .actions .continue a:hover {
    text-decoration: underline;
  }
}

.landing #more {
  position: relative;
  width: 100%;
  max-width: calc(100%);
  padding-top: 60px;
  margin: 0 auto;
  text-align: center;
  background-color: #EEEDEF;
  clip-path: polygon(0 25px, 100% 0, 100% 100%, 0 100%);
  color: #232323;
}


.landing #more .card-details {
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
  color: #898989;
}

.card-details p {
  padding: 1em;
}

.landing #more h1.title {
  font-weight: 500;
  margin: 0;
  font-size: 2.5rem;
  color: black;
}

.landing #more h3.subtitle {
  font-weight: 300;
  margin: 0;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.landing #more .card-details p {
  max-width: calc(100% - 50px);
  font-weight: 400;
  margin: 0 auto;
  font-size: 20px;
}
.landing #more h2.title {
  font-weight: 500;
  margin: 0;
  font-size: 1.8rem;
  color: #565656;
}


.landing .boxes {
  position: relative;
  width: 800px;
  max-width: calc(100% - 20px);
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "product"
    "history"
    "digicard"
    "ownership"
    "marketplace";
}

.landing .blockchain {
  padding: 5px 25px;
}

.landing .box {
  position: relative;
  margin: 15px auto;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  text-align: center;
  background-color: white;
  color: #232323;
}

.landing .box#product {
  grid-area: product;
}
.landing .box#ownership {
  grid-area: ownership;
}
.landing .box#history {
  grid-area: history;
}
.landing .box#marketplace {
  grid-area: marketplace;
}

.landing .boxes .empty {
  grid-area: empty;
}

.landing .digi-card {
  grid-area: digicard;
  max-width: 100%;
}

.landing .digi-card img {
  max-width: 100%;
}

.landing .box .title {
  width: calc(100%);
  background-color: black;
  color: white;
  border-bottom: 2px solid var(--primary);
  font-size: 0.8rem;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.landing .box .content {
  padding: 10px;
  width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  font-weight: 300;
  font-size: 0.8rem;
}

/* Det. Product Specs*/
.landing .box#product .content .entry {
  text-align: left;
  font-size: 1rem;
}
.landing .box#product .content .entry .value {
  float: right;
  font-weight: 700;
}

/* comp. ownership hist. */
.landing .box#ownership .content table {
  text-align: center;
  width: 100%;
  font-size: 1rem;
  height: 100%;
}


.landing .box#ownership .content table .owner {
  text-align: left;
}
.landing .box#ownership .content table .tx {
  text-align: right;
}

/* compl. trading hist. */
.landing .box#history .content img {
  max-width: 100%;
  max-height: 100%
}
/* inst. global market. */
.landing .box#marketplace .content {
  overflow: hidden;
  font-size: 1rem;
  width: calc(100% - 20px);
  padding: 15px 10px;
}
.landing .box#marketplace .content img {
  width: 75px;
  margin-right: 15px;
  float: left;
}

.landing .box#marketplace .content p {
  margin: 15px 0;
}

.landing #more ul {
  text-align: left;
  list-style: none;
  padding: 25px 0;
  width: 800px;
  max-width: calc(100% - 30px);
  margin: 0px auto;
}
.landing #more ul li {
  color: #464646;
  font-weight: 300;
  padding-bottom: 10px;
}

.landing #more ul li:before {
  content: "\2022";
  color: var(--primary);
  font-weight: 700;
  display: inline-block;
  width: 15px;
  font-size: 1.5rem;
  line-height: .5;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}

.landing .cards-rarity {
  width: calc(100% - 30px);
  margin: 0px auto;
  display: grid;
  grid-template-columns: 1fr;
  padding: 25px 0;
  max-width: calc(100% - 30px);
}

.landing .card-rarity {
  border-radius: 5px;
  margin: 10px auto;
  width: 100%;
  max-width: 350px;
}

.landing .card-rarity hr {
  margin-top: 0;
}

.landing .card-rarity img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  margin-bottom: 10px;
}

.landing .card-rarity .name {
  font-size: 1.2rem;
  color: #6e6e6e;
  padding: 0 10px;
  font-weight: 400;
}
.landing .card-rarity p {
  font-size: 1rem;
  margin-top: 5px;
  padding: 0 10px;
  color: #a7a7a7;
  font-weight: 400;
}

@media only screen and (min-width: 800px) {
  .landing .boxes {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "product history"
        "digicard digicard "
        "ownership marketplace";
    background-image: none;
  }
  .landing .boxes .digi-card {
    display: none;
  }
  .landing .boxes .box .content {
    height: 150px;
  }
  .landing .boxes .box#marketplace .content {
    width: calc(100% - 20px);
    height: 100px;
    padding: 35px 10px;
  }
  .landing .box#marketplace .content {
    overflow: hidden;
    font-size: 1rem;
    width: calc(100% - 20px);
  }

  .landing .boxes .box#marketplace .content img {
    width: 100px;
  }
  .landing .boxes .box#marketplace .content p {
    margin-top: 15px;
  }
  .landing .cards-rarity {
    grid-template-columns: 1fr 1fr;
  }

  .landing .box#product .content .entry {
    text-align: left;
    font-size: 1rem;
    line-height: 37.5px;
  }
}
.centered-desktop {
  display: none;
}

.landing #more .faq {
  font-weight: 300;
  text-align: left;
  list-style: none;
  font-size: 1rem;
  padding: 25px 0;
  width: 800px;
  max-width: calc(100% - 30px);
  margin: 0px auto;
}

.landing #more .faq h2.title {
  text-align: center;
  margin-bottom: 25px;
}

.landing .faq .question {
  font-weight: 500;
  font-size: 24px;
}
.landing .faq .answer {
  padding: 10px 0;
  color: #676767;
  font-size: 20px;
  font-weight: 300;
}
.landing .faq .answer b {
  color: #898989;
}
.landing .faq .answer a {
  text-decoration: none;
  color: var(--primary);
}

.landing footer {
  background: linear-gradient(180deg, #252525 0%, black 100%);
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  color: white;
}
.landing footer a {
  color: white;
  border-bottom: 1px dotted white;
  margin: 0 10px;
}
.landing footer a:hover {
  border-bottom-style: solid;
}


@media only screen and (max-width: 600px) {

  .landing footer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .landing footer div {
    width: 100%;
  }
}

@media only screen and (min-width: 1300px) {
  .landing #landingpage .topps {
    height: 100px;
  }
  .landing #landingpage .cards {
    padding-bottom: 75px;
  }
  .landing .boxes {
    width: 1300px;
    grid-template-columns: 1fr 1fr;
    background-image: url(../../assets/images/center.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .centered-desktop {
    display: block;
  }
  .landing .cards-rarity {
    width: 1300px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (min-width: 1600px) {
  .landing #more {
    margin-top: -25px;
    padding-top: 125px;
    clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 100%);
  }
  .landing .boxes {
    width: 1500px;
    grid-template-columns: 1fr 1fr;
  }
  .centered-desktop {
    display: none;
  }
  .landing .cards-rarity {
    width: 1500px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .landing .card-rarity img {
    width: 250px;
  }
}

@media only screen and (max-width: 300px) {
  .landing .boxes {
    grid-template-columns: 1fr;
    grid-template-areas:
        "product"
        "history"
        "digicard"
        "ownership"
        "marketplace";
  }
  .landing .boxes .box .content,
  .landing .box .content .entry {
    font-size: 0.8rem !important;
  }
  .landing .boxes .box#marketplace .content p {
    margin: 3px 0;
  }
}