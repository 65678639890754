:root{
  --button-loader-color: white;
  --button-loader-height: 15px;
  --button-loader-height-small: 7px;
}

.ball-loader {
  position: absolute;
  width: 80px;
  height: var(--button-loader-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ball-loader-ball {
  height: var(--button-loader-height);
  width: var(--button-loader-height);
  background-color: var(--button-loader-color);
  border-radius: 50%;
  -webkit-animation: grow 1s ease-in-out infinite alternate;
  animation: grow 1s ease-in-out infinite alternate;
}

.ball-loader-ball:nth-child(1) {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.ball-loader-ball:nth-child(2) {
  -webkit-animation-delay: 0.33s;
  animation-delay: 0.33s;
}
.ball-loader-ball:nth-child(3) {
  -webkit-animation-delay: 0.66s;
  animation-delay: 0.66s;
}

@-webkit-keyframes grow {
  to {
    -webkit-transform: scale(0.15);
    transform: scale(0.15);
  }
}

@keyframes grow {
  to {
    -webkit-transform: scale(0.15);
    transform: scale(0.15);
  }
}

.hide-button-content{
  opacity: 0;
}


.small-loader .ball-loader{
  width:40px;
}
.small-loader .ball-loader-ball{
  height: var(--button-loader-height-small);
  width: var(--button-loader-height-small);
}
