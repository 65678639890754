#purchaseform {
  margin: 0 auto;
}

#purchaseform .grey {
  background-color: #b8b8b8;
  color: #ffffff;
  cursor: auto !important;
}
#purchaseform .saving span {
  /**
   * Use the blink animation, which is defined above
   */
  animation-name: blink;
  /**
   * The animation should take 1.4 seconds
   */
  animation-duration: 1.4s;
  /**
   * It will repeat itself forever
   */
  animation-iteration-count: infinite;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;
}

#purchaseform .saving span:nth-child(2) {
  /**
   * Starts the animation of the third dot
   * with a delay of .2s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: .2s;
}

#purchaseform .saving span:nth-child(3) {
  /**
   * Starts the animation of the third dot
   * with a delay of .4s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: .4s;
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}


#purchaseform .right {
  float: right;
}

#purchaseform .title {
  margin: 0;
  color: #333333;
  font-size: 35px;
}

#purchaseform .formbox {
  margin-top: 10px;
  float: left;
  width: 100%;
}

#purchaseform .buywax {
  color: rgb(226, 48, 48);
  text-decoration: none;
}

#purchaseform .half {
  width: calc(50% - 10px);
  float: left;
}
#purchaseform .l {
  margin-right: 10px;
}
#purchaseform .r {
  margin-left: 10px;
}
#purchaseform .quarter {
  width: calc(25% - 10px);
  float: left;
}

#purchaseform input, #purchaseform select, #purchaseform .input-wrapper {
  width: calc(100% - 34px);
  border: 2px solid #eeeeee;
  background-color: #eeeeee;
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
}
#purchaseform select {
  width: calc(100% - 10px);
}

#purchaseform input#month {
  width: 34px;
  margin-right: 10px;
}

#purchaseform input#year {
  width: 45px;
}

#purchaseform input#cvc {
  /* width: 24px; */
}

#purchaseform input:focus {
  border-color: "#151515";
}

#purchaseform .summary-title {
  font-size: 24px;
}

#purchaseform .preview {
  float: right;
  height: 250px;
  margin: 0 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
#purchaseform input#quantity {
  width: auto;
  text-align: center;
}

#purchaseform .quantity-changer button {
}


@media only screen and (max-width: 500px) {
  #purchaseform .preview {
    float: initial;
    display: block;
    margin: 0 auto;
  }
}


@media only screen and (max-width: 400px) {
  #purchaseform .half {
    width: calc(100%);
    margin: 0;
  }
  #purchaseform button {
    width: calc(100%);
    margin: 0;
    margin-top: 10px;
  }
  #purchaseform input {
    width: calc(100% - 25px);
    margin-top: 10px;
  }
  #purchaseform input#quantity {
    width: calc(100% - 25px);
  }
}

.moonpay {
  padding: 0;
  max-height: calc(100% - 50px);
  max-width: calc(100% - 50px);
  height: 550px;
  width: 450px;
  overflow: hidden;
}

.moonpay iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.popup-closer {
  position: fixed;
  z-index: 5000;
  bottom: 10px;
  color: white;
  cursor: pointer;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.popup-closer:hover {
  font-weight: 700;
}