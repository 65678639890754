#profile {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
}

#profile .basic {
    padding-bottom: 25px;
}

#profile .basic .avatar {
    max-height: 80px;
}

#profile .basic .name a {
    color: #565656;
}

#profile .basic .nick {
    font-weight: 900;
}

#profile .basic .accountName {
    font-weight: 300;
}

#profile .stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 25px;
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
}

#profile .stats .data-box .nr {
    font-size: 1.5rem;
    font-weight: 900;
    color: var(--primary);
}

#profile .stats .data-box .info {
    font-size: 1rem;
    font-weight: 300;
    color: #565656;
}

/* #profile .actions {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    width: 450px;
    margin: 0 auto;
    max-width: 100%;
}

#profile .actions button {
    font-weight: 700;
} */
#profile .profile-links a {
    color: #565656;
}

#profile .profile-links a.active {
    border-bottom: 1px solid #565656;
}

#profile .actions button {
    margin: 0 auto;
    font-size: 18px;
    margin: 10px;
}

#profile .title {
    text-align: left;
    color: #C4C4C4;
    font-style: italic;
    font-size: 20px;
    margin-bottom: 5px;
}

#profile #inventory {
    margin-top: 0;
}


@media only screen and (max-width: 300px) {
    #profile .stats {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    #profile .actions {
        grid-gap: 10px;
        grid-template-columns: 1fr;
    }

    #profile .actions button {
        width: 100%;
    }
}

@media only screen and (max-width: 220px) {
    #profile .stats {
        grid-template-columns: 1fr;
    }
    #profile .stats .data-box {
        margin: 10px 0;
    }
    #profile .stats .data-box:last-child {
        grid-column-start: 1;
    }
}

@media only screen and (min-width: 450px) {
    #profile .stats {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 750px) {
    
}

@media only screen and (min-width: 1000px) {
    
}

@media only screen and (min-width: 1400px) {
    
}