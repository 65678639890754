:root {
    font-size: 18px;
    --text1-color: rgb(113,113,113);
    --text1-color-hover: rgb(25,25,25);
    --main-bg: #EEEDEF;

    // Navigation
    --nav-bg-top: #252525;
    --nav-bg-bottom: black;
    
    --nav-sub-bg: white;



    // Color theme
    --primary: black;
    --primary-light: #252525;
}