#unpack {
  position: fixed;
  padding: 0 25px;
  height: 100vh;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
}

#unpack, #unpack * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#unpack video {
  margin: 0 auto;
  outline: none;
  border: none;
  border: 0px solid transparent;
  max-height: calc(90vh - 80px);
}

#unpack .video-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  transform-style: flat;
  -webkit-transform-style: flat;
  overflow: hidden;
  transition: opacity 1s;
  -webkit-transition: opacity 1s;
  clip-path: inset(1px 1px);

  display: flex;
  justify-content: center;
  vertical-align: center;
  background: black;
}

#unpack .video-wrapper.hidden {
  opacity: .1;
}

#unpack .video-wrapper #video {
  position: relative;
}

#unpack .cardstodrop {
  position: absolute;
  z-index: 1;
  top: 0;
  height: calc(100% - 112px);
  perspective: 1000px;
  width: calc(100% - 25px);
}


#unpack .cardstodrop .goto {
  position: absolute;
  text-align: center;
  z-index: 10000;
  left: 50%;
  bottom: 100px;
  transform-style: flat;
  -webkit-transform-style: flat;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

#unpack .cardstodrop img {
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  top: -1000px;
  left: 50%;
  display: block;
  max-width: 350px;
  max-height: 350px;
  height: auto;
  width: auto;
  transform-style: flat;
  -webkit-transform-style: flat;
  transition: top .4s ease-in-out, transform .8s;
  -webkit-transition: top .4s ease-in-out, transform .8s;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, .1));
  cursor: pointer;
}

#unpack .cardstodrop img.dropped {
  top: 50%;
  transform: translate(-50%, -50%) rotateX(25deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg);
}

#unpack .cardstodrop img.dropped:nth-child(even) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
}

#unpack .cardstodrop img.dropped:nth-child(odd) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-3deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-3deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-3deg);
}

#unpack .cardstodrop img.dropped:nth-child(1) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
}
#unpack .cardstodrop img.dropped:nth-child(2) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-3deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-3deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-3deg);
}
#unpack .cardstodrop img.dropped:nth-child(3) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
}
#unpack .cardstodrop img.dropped:nth-child(4) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(3deg);
}
#unpack .cardstodrop img.dropped:nth-child(5) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
}
#unpack .cardstodrop img.dropped:nth-child(8) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
}
#unpack .cardstodrop img.dropped:nth-child(15) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(-8deg);
}
#unpack .cardstodrop img.dropped:nth-child(12) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
}
#unpack .cardstodrop img.dropped:nth-child(18) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
}
#unpack .cardstodrop img.dropped:nth-child(25) {
  transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -webkit-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
  -moz-transform: translate(-50%, -50%) rotateX(25deg) rotateZ(8deg);
}


#unpack .cardstodrop img.dropped:hover {
  transform: translate(-50%, -50%) rotateX(0deg) scale(1.2);
  -webkit-transform: translate(-50%, -50%) rotateX(0deg) scale(1.2);
  -moz-transform: translate(-50%, -50%) rotateX(0deg) scale(1.2);
}

@keyframes dropCards {
  0% {
    top: -1000px;
  }
  100% {
    top: 50%;
  }
}

#unpack .unboxing-dimmer {
  pointer-events: none;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100vw;
  transform: translate(-50%, -50%);
  z-index: 99999;
  opacity: 1;
}

#unpack .glass {
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.7);
}

#unpack .unboxing-dimmer-pack {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#unpack .unboxing-dimmer-done {
  pointer-events: none;
  opacity: 0;
  animation: dim-animation2 linear 0.4s;
  -webkit-animation: dim-animation2 linear 0.4s;
}

@keyframes dim-animation2 {
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

#unpack .loading-text {
  color: white;
  text-align: center;
  padding: 2em;
}

#unpack .tilt3-wrapper {
  transform: translate3d(900, 0, 0);
  perspective: 1000px;
  animation: shake 1.32s ease-in-out !important;
  -webkit-animation: shake 1.32s ease-in-out !important;
  animation-iteration-count: infinite !important;
  -webkit-animation-iteration-count: infinite !important;
  animation-direction: alternate !important;
  -webkit-animation-direction: alternate !important;
}

@keyframes shake {
  0%, 100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
  }

  50% {
    transform: scale(1.1) rotateX(5deg);
    -webkit-transform: scale(1.1) rotateX(5deg);
    -moz-transform: scale(1.1) rotateX(5deg);
  }
}

.safari-fix {
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 99999;
  color: white;
}

.safari-fix p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.unpack-loader,
.unpack-loader:before,
.unpack-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.unpack-loader {
  color: #ffffff;
  font-size: 10px;
  margin: auto auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.unpack-loader:before,
.unpack-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.unpack-loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.unpack-loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

