#createtrade {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
}

#createtrade .info {
    font-style: italic;
    font-size: 0.9rem;
    line-height: 1rem;
    text-align: center;
    color: #C4C4C4;
    padding: 10px 15px;
}

#createtrade .actions {
    max-width: 100%;
}

#createtrade .nofollowing {
    color: #909090;
    padding: 25px 0;
    max-width: 100%;
}

#createtrade .nofollowing a {
    color: var(--primary);
}

#createtrade .actions button {
    background-color: transparent;
    width: 100%;
    font-size: 1.2em;
    padding: 5px 0;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    max-width: calc(100%);
}

#createtrade .actions button:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}
#createtrade .friends {
    text-align: center;
}
#createtrade .friends a {
    text-decoration: none;
}

@media only screen and (max-width: 370px) {
    
}

@media only screen and (min-width: 1000px) {
    
}
/*# sourceMappingURL=createtrade.css.map */