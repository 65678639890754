@import url('https://fonts.googleapis.com/css?family=Khand:300,500,700&display=swap');

*:not(i) {

  font-family: 'Mulish', sans-serif;

}

/* *, :after, :before {
    -webkit-box-sizing: initial;
    box-sizing: initial;
} */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #151515;
}

::-webkit-scrollbar-thumb:hover {
  background: #333;
}


input, select, button {
  font-size: 18px;
}

a {
  text-decoration: none;
}
html {
  overflow-y: scroll;
}
html, body {
  overflow-x: auto;
  min-height: 100%;
}

html, body {
  margin: 0;
  background-color: var(--main-bg);
  height: inherit !important;
  font-family: 'Khand', Arial, Helvetica, sans-serif;
  font-size: 18px;
}

nav {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}

nav .top {
  height: 80px;
  background: linear-gradient(180deg, var(--nav-bg-top) 0%, var(--nav-bg-bottom) 100%);
  color: white;
  overflow: hidden;
}

nav .top .avatar {
  max-height: calc(100% - 20px);
  margin: 10px;
  float: left;
}

nav .top .info {
  padding: 18px 0;
  float: left;
  z-index: 5;
  line-height: 22px;
  color: white;
}

nav .top .info small {
  font-weight: 300;
}

nav .top .header_gpk {
  height: 40px;
  margin-top: 20px;
  position: absolute;
  right: 10px;
  z-index: 0;
}

nav aside {
  position: fixed;
  bottom: 0;
  background-color: var(--nav-sub-bg);
  height: 50px;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, .15);
}

nav aside .logo {
  display: none;
}

nav aside .links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  overflow: hidden;
  width: calc(100%);
  font-size: 0.9rem;
  font-weight: 300;
}

nav aside .links .catalog {
  display: none;
}

nav aside .links a {
  position: relative;
  padding: 0 10px;
  color: var(--text1-color);
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  height: 50px;
  border-bottom: 2px solid transparent;
}
nav aside .links a .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  height: 25px;
  filter: grayscale(100%);
}

nav aside .links a .text {
  display: none;
}

nav aside .links a:hover {
  color: var(--text1-color-hover);
}
nav aside .links a.active {
  color: var(--primary);
}

nav aside .links a.active .icon {
  filter: grayscale(0%);
}

nav aside .links .signin {
  color: var(--primary);
  float: right;
  cursor: pointer;
}
nav aside .links .signin:hover {
  color: var(--primary-light);
}

nav aside .links .account {
  float: right;
  color: var(--text1-color);
}

nav aside img {
  height: calc(100% - 10px);
  margin-top: 5px;
  float: left;
  margin-right: 5px;
}

.container {
  padding: 15px;
  margin: 0 auto;
  width: 1400px;
  max-width: calc(100% - 30px);
  margin-top: 85px;
  margin-bottom: 50px;
  box-sizing: border-box;
}

.container .extralinks {
  text-align: right;
  margin-bottom: 5px;
}

.container .extralinks a {
  color: #6e6e6e;
  text-decoration: none;
  font-size: 1rem;
}
.container .extralinks a.arrow_left::before {
  content: '← ';
  text-align: left;
}

.container .extralinks a.arrow_right::after {
  content: ' →';
}

.container .box {
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.container .box ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, .2);
}

.container .box .box {
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0;
}

.container .box .Pagination {
  border-bottom: none;
}
.container .box, .container .box:after, .container .box:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.container .box .title {
  color: var(--primary);
  margin: 0;
  font-weight: 900;
  font-size: 3.2em;
  text-align: center;
  text-shadow: 1px 3px rgba(0, 0, 0, 0.25);
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  margin-top: 15px;
}

.container .box .subtitle {
  color: #979797;
  text-align: center;
  font-weight: 200;
  margin: 0;
  font-size: 1em;
  font-style: italic;
  margin-bottom: 15px;
}

.container .box img {
  max-width: 100%;
}

.container .box-wrapper .category {
  color: #E4E4E4;
  font-weight: 900;
  font-size: 2.5em;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.TutorialOverlay {

}

.TutorialOverlay .content {
  float: left;
}

.TutorialOverlay .showcase-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  height: 350px;
  width: 350px;
}

.TutorialOverlay .showcase-wrapper .showcase-component {
  height: 250px;
  width: 350px;
}

.TutorialOverlay .showcase-component img.preview {
  max-height: 100%;
  max-width: 100%;
}

.TabSelector {
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  margin-bottom: 10px;
}

.TabSelector .selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 25px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  text-align: center;
  opacity: .5;
}
.TabSelector .selector:hover {
  opacity: .9;
}
.TabSelector .selector.active {
  opacity: 1;
  color: black;
  border-bottom: 4px solid var(--primary);
}

.PackView {
  border-radius: 5px;
  max-width: 100%;
  width: 250px;
  float: left;
  position: relative;
  text-align: left;
  margin: 50px;
}

.PackView.clickable {
  cursor: pointer;
}

.CardView {
  border-radius: 5px;
  max-width: 100%;
  width: 250px;
  float: left;
  position: relative;
  text-align: left;
  display: flex;
}

.CardView:hover {
  z-index: 5;
}

.CardView.portrait {
  max-width: 100%;
  width: 310px;
}

@media only screen and (max-width: 550px) {
  .CardView, .PackView {
    width: 200px;
  }
}

.CardView .preview {
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.CardView .info {
  color: white;
  text-align: center;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  align-content: end;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  width: calc(100%);
  opacity: 0;
  transition: opacity .2s;
}


.CardView .info * {
  grid-column: span 2;
}
.CardView .info .single {
  grid-column: span 1;
}

.CardView:hover .info {
  opacity: 1;
  color: white;
}
.CardView:hover .info a {
  color: white;
}

.CardView .info button {
  width: calc(100% - 20px);
  margin: 10px;
}
.CardView .info img {
  height: 28px;
  filter: grayscale(1) brightness(255);
}

#FilterBar {
  margin-bottom: 10px;
}

#FilterBar input {
  padding: 5px 0px;
  outline: none;
  border: none;
  width: 50%;
}

#FilterBar select {
  border: none;
  float: right;
  height: 100%;
  outline: none;
  padding: 4px;
  margin-left: 10px;
  background-color: transparent;
}
#FilterBar select:last-child {
  margin-left: 0;
}

#FilterBar select.longer {
  width: 120px;
}

#FilterBar img {
  float: right;
  cursor: pointer;
  height: 18px;
  margin: 8px 0;
}

@media only screen and (max-width: 650px) {
  #FilterBar {
    text-align: center;
  }
  #FilterBar input {
    width: 100%;
    text-align: center;
  }
  #FilterBar select {
    float: initial;
  }
}


.action-card {
  position: relative;
  height: 325px;
  width: 225px;
  background-color: #F9F9F9;
  cursor: pointer;
  margin-right: 10px;
  float: left;
  border-radius: 5px;
}
.action-card.small {
  height: 175px;
  width: 125px;
}
@media only screen and (min-width: 1600px) {
  .action-card.small {
    width: 175px;
    height: 250px;
  }
}
.action-card.full {
  width: calc(100% - 10px);
}
.action-card.half {
  width: calc(50% - 10px);
}

.action-card img {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: top .5s;
}
.action-card .text {
  position: absolute;
  bottom: 5px;
  left: 50%;
  text-align: center;
  width: calc(100% - 10px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: font-size .5s;
  color: #979797;
}

.action-card:hover {
  background-color: #ebe9e9;
}
.action-card:hover img {
  top: 35%;
}
.action-card:hover .text {
  font-size: 1.2em;
}


.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 1100;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
}

.dimmer.active {
  display: block;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2000;
  padding: 10px;
  background-color: white;
  max-height: calc(100% - 40px);
  max-width: calc(100% - 40px);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  display: none;
  overflow: auto;
}

.popup.active {
  display: block;
}


.InspectCard {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2000;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: none;
}

.InspectCard.active {
  display: block;
}

.flip-card {
  background-color: transparent;
  height: 550px;
  width: 350px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 550px;
  width: 350px;
}

.flip-card-front img, .flip-card-back img {
  height: 550px;
  width: 350px;
  margin: 0 auto;
}

.flip-card-back img {
  transform: rotateZ(90deg);
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

.InspectCard .actions {
  text-align: center;
}

.InspectCard .actions .action-item {
  width: 25px;
  height: 25px;
  margin: 15px;
  display: inline-block;
}

.InspectCard .close, .InspectCard .flip-action, .InspectCard .chain, .InspectCard .share, .InspectCard .withdraw {
  position: relative;
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
  margin: 15px auto;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: background-color .5s;
}

.InspectCard .actions img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.InspectCard .flip-action {
  float: left;
}

.Pagination {
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.Pagination .pager {
  text-decoration: none;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
}
.Pagination .pager:hover {
  text-decoration: underline;
}
.Pagination .pager.active {
  cursor: default;
  text-decoration: underline;
}

.ActivityFeed {

}
.ActivityFeed h1 {
  margin: 0;
}

.ActivityFeed .feed {
  display: grid;
  gap: 15px;
  grid-gap: 15px;
}

.ActivityFeed .feed .box {
  color: #cccccc;
  text-align: left;
  line-height: 1rem;
  margin-bottom: 0;
}
.ActivityFeed .feed .box img {
  height: 18px;
  margin-top: -2px;
  margin-right: 5px;
  float: left;
}
.ActivityFeed .feed .box .date {
  color: #cccccc;
  margin: 0 10px;
}
.ActivityFeed .feed .box a {
  color: var(--primary);
}
.ActivityFeed .feed .box .name {
  color: #636363;
  margin-right: 5px;
}
.ActivityFeed .feed .box .tx {
  float: right;
  margin-left: 10px;
  color: #cccccc;
}

button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  padding: 4px 10px;
  background-color: var(--primary);
  color: white;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 18px;
}

button.disable-cursor {
  cursor: default !important;
}

button.full {
  width: 100%;
}

button.secondary {
  border-color: var(--primary);
  background-color: white;
  color: var(--primary);
}

button.gray {
  background-color: #b8b8b8;
  color: #ffffff;
}

button:hover {
  background-color: var(--primary-light);
  border-color: var(--primary-light);
  color: white;
}

button.gray:hover {
  background-color: #cccccc;
  border-color: #cccccc;
}

button .loader {
  height: 100%;
}


@media only screen and (min-width: 800px) {
  .container {
    padding: 25px;
    max-width: calc(100% - 50px);
  }

  .container .box {
    border-radius: 5px;
  }
}

/* desktop */
@media only screen and (min-width: 1000px) {

  .mobile {
    display: none;
  }

  nav aside {
    position: relative;
    height: 30px;
    box-shadow: none;

  }

  nav aside .logo {
    display: block;
    float: left;
    margin: 5px 15px;
  }
  nav aside .links {
    display: initial;
    width: calc(100%);
  }
  nav aside .links a {
    margin: 0 5px;
    float: left;
    height: 30px;
    line-height: 30px;
  }

  nav aside .links a.catalog {
    display: inline-block;
  }

  nav aside .links a .icon {
    position: relative;
    float: left;
    transform: none;
    left: initial;
    top: initial;
    margin-right: 5px;
    max-height: 20px;
    margin-top: 5px;
  }

  nav aside .links a .text {
    display: inline-block;
  }

  .container {
    margin-top: 112px;
  }

}


[tooltip] {
  position: relative;
  display: inline-block;
}
[tooltip]::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  z-index: 99;
  opacity: 0;
}

[tooltip-position="left"]::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg);
}
[tooltip-position="top"]::before {
  left: 50%;
}
[tooltip-position="bottom"]::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}
[tooltip-position="right"]::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  padding: 4px 2px;
  font-size: 18px;
  min-width: 100px;
  border-radius: 5px;
  pointer-events: none;
  padding: 4px 4px;
  z-index: 99;
  opacity: 0;
}

[tooltip-position="left"]::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}
[tooltip-position="top"]::after {
  left: 50%;
}
[tooltip-position="bottom"]::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}
[tooltip-position="right"]::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
}


/* If mobile is flipped */
@media only screen and (max-height: 375px) {
  nav {
    position: relative;
  }

  .container {
    margin-top: 0px;
  }
}

.swal2-confirm, .swal2-cancel {
  outline: none !important;
  padding: 4px 10px !important;
  background-color: var(--primary) !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 2px solid transparent !important;
  cursor: pointer !important;
  font-size: 18px !important;
}
.swal2-confirm:focus, .swal2-cancel:focus {
  box-shadow: unset !important;
}

.swal2-cancel {
  background-color: #b8b8b8 !important;
  color: #fff !important;
}

.grayscale {
  filter: grayscale(100%);
}

.swal2-container {
  z-index: 9999999 !important;
}