.tilt3-front {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(1000px);
  -webkit-transform: translate(-50%, -50%) perspective(1000px);
  -moz-transform: translate(-50%, -50%) perspective(1000px);
}
.tilt3-back {
  position: absolute;
  visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(1000px);
  -webkit-transform: translate(-50%, -50%) perspective(1000px);
  -moz-transform: translate(-50%, -50%) perspective(1000px);
}


.tilt3-rotator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes cardanimation {
  0% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
  49.99% {
    transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -moz-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -webkit-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
  }
  50% {
    transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    -webkit-transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    -moz-transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
  }
  100% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
}

@keyframes frontanimation {
  0% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  49.99% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  50% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  100% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
}

@keyframes backanimation {
  0% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  49.99% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  50% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  100% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
}

@keyframes cardanimation2 {
  0% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
  49.99% {
    transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
    transform: rotateX(90deg) rotateY(-45deg) perspective(1000px);
  }
  50% {
    transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -webkit-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
    -moz-transform: rotateZ(45deg) rotateY(-90deg) perspective(1000px);
  }
  100% {
    transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -moz-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
    -webkit-transform: rotateZ(0deg) rotateY(0deg) perspective(1000px);
  }
}

@keyframes frontanimation2 {
  100% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  50% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  49.99% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  0% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
}

@keyframes backanimation2 {
  0% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  49.99% {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  }
  50% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
  100% {
    visibility: hidden !important;
    opacity: 0;
    z-index: -1000;
  }
}


.tilt3-wrapper {
  display: inline-block;
  overflow: visible;
  position: relative;
  transform: rotateX(0deg) rotateY(-5deg) perspective(1000px);
  -webkit-transform: rotateX(0deg) rotateY(-5deg) perspective(1000px);
  -moz-transform: rotateX(0deg) rotateY(-5deg) perspective(1000px);
}

.tilt3-wrapper, .tilt3-back, .tilt3-front {
  animation-duration: 0.3s !important;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.2s !important;
  -webkit-animation-fill-mode: forwards !important;
}

.tilt3-wrapper.tilt3-show-backside {
  animation-name: cardanimation;
  -webkit-animation-name: cardanimation;
}
.tilt3-show-backside .tilt3-front {
  animation-name: frontanimation;
  -webkit-animation-name: frontanimation;
}
.tilt3-show-backside .tilt3-back {
  animation-name: backanimation;
  -webkit-animation-name: backanimation;
}

.tilt3-wrapper.tilt3-flip-back {
  animation-name: cardanimation2;
  -webkit-animation-name: cardanimation2;
}
.tilt3-flip-back .tilt3-front {
  animation-name: frontanimation2;
  -webkit-animation-name: frontanimation2;
}
.tilt3-flip-back .tilt3-back {
  animation-name: backanimation2;
  -webkit-animation-name: backanimation2;
}
.tilt3-img-sizer {
  visibility: hidden;
}

.tilt3-rotator {
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: perspective(1000px);
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  flex-shrink: 0;
}

.tilt3-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: center;
}

.tilt3-card-pack-shadow {
  transform: translateZ(-15px) scale(1.002) perspective(1000px);
  -webkit-transform: translateZ(-15px) scale(1.002) perspective(1000px);
  -moz-transform: translateZ(-15px) scale(1.002) perspective(1000px);
  background: #515151;
}

.tilt3-shadow {
  background-image: unset;
  background: #313131;
  transform: translateZ(0.1px);
  -moz-transform: translateZ(0.1px);
  -webkit-transform: translateZ(0.1px);
}


.cardimg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card-pack, .inner {
  background-size: cover;
}

.card-pack {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform: perspective(1000px);
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  flex-shrink: 0;
}

.inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-pack-inner-hero {
  transform: translateZ(5px);
  -webkit-transform: translateZ(5px);
  -moz-transform: translateZ(5px);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;

  -webkit-mask-image: url(../../assets/images/pack_masks/5.png);
  mask-image: url(../../assets/images/pack_masks/5.png);
}


.card-pack-inner-titan {
  transform: translateZ(5px);
  -webkit-transform: translateZ(5px);
  -moz-transform: translateZ(5px);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  mask-image: url(../../assets/images/pack_masks/30.png);
  -webkit-mask-image: url(../../assets/images/pack_masks/30.png);
}

.shadow {
  background-image: unset;
  background: #313131;
  transform: translateZ(0.1px);
  -webkit-transform: translateZ(0.1px);
  -moz-transform: translateZ(0.1px);
}

.card-pack-shadow {
  transform: translateZ(-15px) scale(1.002);
  -webkit-transform: translateZ(-15px) scale(1.002);
  -moz-transform: translateZ(-15px) scale(1.002);
  background: #515151;
}

.glass {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(20px) scale(1.1);
  -moz-transform: translateZ(20px) scale(1.1);
  -webkit-transform: translateZ(20px) scale(1.1);
  background-color: transparent;
  box-shadow: inset 0 0 15px rgba(55, 55, 55, 0.7);
  overflow: hidden;
}

.glass-bottom {
  -webkit-transform: translateY(55%) rotateX(90deg) scale(1.1) scaleY(0.1);
  -moz-transform: translateY(55%) rotateX(90deg) scale(1.1) scaleY(0.1);
  transform: translateY(55%) rotateX(90deg) scale(1.1) scaleY(0.1);
}
.glass-top {
  transform: translateY(-55%) rotateX(90deg) scale(1.1) scaleY(0.1);
  -webkit-transform: translateY(-55%) rotateX(90deg) scale(1.1) scaleY(0.1);
  -moz-transform: translateY(-55%) rotateX(90deg) scale(1.1) scaleY(0.1);
}
.glass-right {
  transform: translateX(55%) rotateY(90deg) scale(1.1) scaleX(0.15);
  -webkit-transform: translateX(55%) rotateY(90deg) scale(1.1) scaleX(0.15);
  -moz-transform: translateX(55%) rotateY(90deg) scale(1.1) scaleX(0.15);
}
.glass-left {
  transform: translateX(-55%) rotateY(90deg) scale(1.1) scaleX(0.15);
  -webkit-transform: translateX(-55%) rotateY(90deg) scale(1.1) scaleX(0.15);
  -moz-transform: translateX(-55%) rotateY(90deg) scale(1.1) scaleX(0.15);
}
.glass-back {
  transform: translateZ(-20px) scale(1.1);
  -webkit-transform: translateZ(-20px) scale(1.1);
  -moz-transform: translateZ(-20px) scale(1.1);
}

.shine {
  position: absolute;
  width: 1000px;
  height: 100px;
  margin-left: -100px;
  transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  background: -webkit-linear-gradient(top, transparent, rgba(200, 200, 200, 0.3), transparent);
  animation: pack-shine 6s ease-in-out infinite;
  -webkit-animation: pack-shine 6s ease-in-out infinite;
  -moz-animation: pack-shine 6s ease-in-out infinite;
}

@-webkit-keyframes pack-shine {
  0%, 100% {
    margin-top: -100px;
  }
  50% {
    margin-top: 800px;
  }
}

@keyframes pack-shine {
  0%, 100% {
    margin-top: -100px;
  }
  50% {
    margin-top: 800px;
  }
}
